//import dependencies
import React from "react"
import { Script, ScriptStrategy, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Reactmarkdown from "react-markdown"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

 
// export contact page with SEO, titles, headings

export default function ContactPage ({ data })
{
  const map = getImage(data.strapiContact.locationImage.localFile)
  const bodyText = data.strapiContact.body.data.body
  const headline = data.strapiContact.headline
  const shortDesc = "Reach out to the Toristy team"
  const seo = {
    metaTitle: data.strapiContact.headline,
    metaDescription: "Reach out to the Toristy team",
  }  
  
  return (
    <Layout>
      <Seo seo={seo} />
      <div className="container"><Headings title={headline} description={shortDesc} /></div>
      <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
      <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
      <div className="pb-4"><GatsbyImage image={map} alt="Toristy locations" className="rounded-2xl grayscale hover:grayscale-0" /></div>
                <div className="md:7/12 lg:w-6/12">
                  <div className="p-5 text-gray-900"><Reactmarkdown>{bodyText}</Reactmarkdown></div>
                  <div className="space-y-4">
                  <div className="p-6">
                  <Script src="https://www.google.com/recaptcha/api.js" strategy={ScriptStrategy.offMainThread} defer async></Script>
                    <form name="contact" method="POST" action="https://usebasin.com/f/58ec19dbe96f">
                   
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col text-brandgreen">
                        <label htmlFor="first-name">First name*</label>
                        <input type="text" id="first-name" name="first-name" className="form-input px-3 py-2 rounded-md" required />
                        </div>
                        <div className="flex flex-col text-brandgreen">
                        <label htmlFor="last-name">Last name*</label>
                        <input type="text" id="last-name" name="last-name" className="form-input px-3 py-2 rounded-md" required />
                        </div>
                        <div className="flex flex-col text-brandgreen">
                        <label htmlFor="email">Email*</label>
                        <input type="email" id="email" name="email" className="form-input px-3 py-2 rounded-md" required />
                        </div>
                        <div className="flex flex-col text-brandgreen">
                        <label htmlFor="phone">
                            <div className="flex align-items">
                            <span className="ml-auto">Phone</span>
                            </div>
                        </label>
                        <input type="tel" id="phone" name="phone" className="form-input px-3 py-2 rounded-md" />
                        </div>
                        <div className="flex flex-col col-span-2 text-brandgreen">
                        <label htmlFor="subject">Subject*</label>
                        <input type="text" id="subject" name="subject" className="form-input px-3 py-2 rounded-md" required />
                        </div>
                        <div className="flex flex-col col-span-2 text-brandgreen">
                        <label htmlFor="subject">
                            <div className="flex align-items">
                            Message*
                            <span className="ml-auto opacity-75">Max. 500 characters</span>
                            </div>
                        </label>
                        <textarea maxLength="500" rows="4" type="text" id="subject" name="subject" className="form-input text-black px-3 py-2 rounded-md" required />
                        </div>
                    </div>
                    <div className="g-recaptcha pb-2" data-sitekey="6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy"></div>
                    <div className="flex justify-end py-4">
                        <button type="submit" className="bg-brandblue text-white  py-2 px-4 rounded focus:ring focus:ring-blue-300 hover:bg-brandgreen">
                        Get in touch
                        </button>
                    </div>
                    </form>
                </div>
            </div>
          </div>
        </div>
        </div>
   

    </Layout>
     )
  }

  export const query = graphql`
  query Contact {
    strapiContact {
      locationImage {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      headline
      body {
        data {
          body
        }
      }
    }
    strapiGlobal {
      siteDescription
    }
  }
  `

